.produit-bloc{
    position: relative;
    width: 80%;
    height: 100% !important;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 20px;
}
.image-produit-bloc{
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.52);
    box-sizing: border-box;
    border-radius: 10px;
    height: 250px;
    max-height: 250px;
    overflow: hidden;
    margin-bottom: 15px;
}
.image-produit-bloc img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
    max-width: 100%;
}
.info-produit-bloc{
    text-align: right;
    padding: 5px;
}
.info-produit-bloc h3{
    font-family: 'Montserrat', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 28px;
line-height: 33px;
text-align: right;

color: #18C0F5;

}
.info-produit-bloc span{
    font-family: 'Fira Sans', sans-serif;
font-style: normal;
font-weight: 200;
font-size: 18px;
color: rgba(0, 0, 0, 0.47);
}
.image-produit-bloc:hover .hover-produit-show{
    height: 100% !important;
}
.hover-produit-show{
    transform:translateY(-100%);
    position: absolute;
    height: 0%;
    width: 100%;
    top: 100%;
    background-color: rgba(24, 295, 245, 0.4);
    transition: 1s;
    cursor: pointer;
    overflow: hidden;
}
.icon-show-produit{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #fff;
}
.icon-show-produit svg{
    font-size: 2rem;
}
.marque-produit{
    text-transform: capitalize;
}
.rupture{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}