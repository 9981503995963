@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

.devis{
    background-color: #18C0F5;
    padding-top: 25px;
    padding-bottom: 25px;
}

.devis .label{
    color: #fff;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
}
.devis input{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.58);
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    font-family: 'Fira Sans', sans-serif;    font-style: normal;
    font-weight: normal;
    margin: 5px;
    margin-bottom: 15px;
}
.devis input::placeholder{
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    color: #C4C4C4;
}
.devis input:focus{
    outline: none;
    border-radius: 10px;
    border: solid #000 1px;
}

.devis textarea{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.58);
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    font-family: 'Fira Sans', sans-serif;    font-style: normal;
    font-weight: normal;
    margin: 5px;
    margin-bottom: 15px;
    height: 250px;
    min-height: 250px;
}

.devis textarea:focus{
    outline: none;
    border-radius: 10px;
    border: solid #000 1px;
}
.devis button{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    border-radius: 25px;
    border: none;
    padding: 5px;
    padding-right: 15px;
    padding-left: 15px;
    background-color: #18C0F5;
    color: #fff;
    font-weight: bold;
    font-size: 22px;
}
.devis button:hover{
    background-color: #fff;
    color: #18C0F5;
}
.devis button:active{
    background-color: #28a745;
    color:#fff;
}
.devis .locationMois{
    position: absolute;
    top: calc(50% - 3px);
    right: 8%;
    transform: translateY(-50%);
    color: lightgray;
}
