@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  h1, h2, h3, h4, h5, h6{
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }
  
  a, p, span {
  font-family: 'Fira Sans', sans-serif;
  }
  
  
  .round-button{
    padding: 5px;
    background-color: #ff5e14;
    width: min-content;
    border-radius: 50px;
    color:#fff
  }
  .navigation a {
    color: #fff !important;
  }
  
  .bloc-chiffre{
    border: solid #fff 1px;
    padding: 15px;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 10px;
    width: 98%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 15px;
    margin-top: 15px;
    transition: 1s;
    overflow: hidden;
  }
  .bloc-chiffre img{
    margin-bottom: 15px;
    max-height: 90px;
  }
  
  @keyframes in {
    from {
      bottom: 0 ;
      height: 0%;
    }
    
    to {
      bottom: 0;
      height: 100%;
    }
  }
  
  @keyframes out {
    from {
      top: 0;
      height: 100%;
    }
    
    to {
      top: 0;
      height: 0%;
    }
  }
  
  .filter-chiffre-clef {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    width: 100%;
    height: 0%;
    background-color: #ff5e14;
    transition: 1s;
    animation-name: out;
    animation-duration: 1s;
  }
  .info-chiffre-clef{
    transition: 1s;
  }
  .bloc-chiffre:hover{
    border: solid #ff5e14 1px;
    margin-top: 0px;
  }
  .bloc-chiffre:hover .info-chiffre-clef{
    transform: scale(1.1);
  }
  .bloc-chiffre:hover .filter-chiffre-clef{
    height: 100%;
    animation-name: in;
    animation-duration: 1s;
  }
  .bloc-chiffre:hover .center{
    background-color: #fff !important;
  }
  .bloc-chiffre span{
    font-size: 36px;
    font-weight: bold;
  }
  .bloc-chiffre p{
    margin-bottom: 15px;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .flexBox {
    display: flex;
  }
  .flexBox-col{
    flex-direction: column;
  }
  .presentation-text h1{
    margin-bottom: 25px;
    color: #FFD102;
  }
  .presentation-text p{
    text-align: justify;
    margin-bottom: 25px;
  }
  .presentation-icon h5{
    font-weight: bold;
    margin-bottom: 10px;
  }
  .presentation p{
    color: #ACACAC;
  }
  .icon-presentation{
    height: 80px;
    width: 80px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
  }
  .center-text{
    text-align: center;
  }
  .f-start {
    justify-content: flex-start;
  }
  .f-end {
    justify-content: flex-end;
  }
  .f-around {
    justify-content: space-around;
  }
  .f-between {
    justify-content: space-between;
  }
  .f-center{
    justify-content: center;
  }
  .pagination nav{
    width: 100%;
  }
  .pagination ul{
    width: max-content;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .icon {
    font-size: 20px;
    margin-right: 15px;
    color: #fff !important;
  }
  p{
    margin-right: 15px;
  }
  .w10 {
    width: 10%;
  }
  .w20 {
    width: 20%;
  }
  .w30 {
    width: 30%;
  }
  .w40 {
    width: 40%;
  }
  .w50 {
    width: 50% !important;
  }
  .w60 {
    width: 60%;
  }
  .w70 {
    width: 70%;
  }
  .w80 {
    width: 80%;
  }
  .w90 {
    width: 90%;
  }
  .w100 {
    width: 100%;
  }
  .center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .right{
    text-align: right;
  }
  .left{
    text-align: left;
  }
  a{
    text-decoration: none;
  }
  .bloc {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .big-bloc{
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .min-bloc {
    padding:15px;
  }
  .no-padding{
    padding:0;
    margin-bottom: 0;
  }
  .square{
    position: relative;
    top: 0;
    left: 0;
    height: 65px;
  }
  .square a{
    height: 100% !important;
  }
  .absolue{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .center-bottom{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .carousel-control-next, .carousel-control-prev{
    width: 5% !important;
  }
  .carousel-caption{
    width: 90% !important;
    left: 5% !important;
  }
  .bold {
    font-weight: bold !important;
  }
  .variant-h3{
    font-size: 30px;
  }
  .caption-text h5{
    margin-bottom: 25px;
  }
  
  .carousel-control-next,
  .carousel-control-prev /*, .carousel-indicators */ {
      filter: invert(100%);
  }
  .footer{
    padding-top: 150px;
    text-align: left !important;
  }
  .footer a{
    text-decoration: none;
    color: #fff;
  }
  .footer-contact-info{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  input[type="text"]:focus {
    outline: none;
    box-shadow: 0px 0px 5px #fff;
    border:1px solid #fff;
  }
  input[type="text"]:focus:hover {
    outline: none;
    box-shadow: 0px 0px 5px #fff;
    border:1px solid #fff;
    border-radius:0;
  }
  .dropdown-toggle::after{
    color: #fff !important;
  }
  .bloc-orange{
    position: absolute;
    top: 0;
  }
  .bloc-orange-negative{
    margin-left: -25px;
  }
  .bloc-footer-icon{
    width: 35px;
    position: relative;
    left: 50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .center-vertical{
    position: relative;
    top: 50%;
    height: max-content;
    transform: translateY(-50%);
  }
  .center-vertical p{
    font-size: 14px;
  }
  
  .ref-client{
    color: #FFD102 !important;
    text-decoration: underline !important;
  }
  .social-footer div{
    padding: 15px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    padding-right: 20px;
  }
  .social-footer div img{
    width: 80%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .social-footer .linkedin {
    border-right: 1px solid #fff;
  
  }
  .breadcrumb{
    background-color: #fff !important;
  }
  .filter{
    list-style: none;
  }
  .sticky{
    position: sticky;
    top: 100px;
    z-index: 1000;
  }
  .filter .active{
    background-color: #FFD102;
  }
  .filter .active{
    color: #fff !important;
    font-weight: bold;
  }
  .filter li{
    position: relative;
    height: max-content;
    padding: 12px;
    width: 110%;
    cursor: pointer;
  }
  .filter a{
    position: relative;
    text-decoration: none !important;
    color: #000;
  }
  .filtet-title{
    position: relative;
  }
  .filtet-title h2{
    font-size: 25px !important;
    color: #565656 !important;
    font-weight: bold;
  }
  .filtet-title div div{
    width: 50% !important;
  }
  #customeToggle{
    background-color: rgba(0,0,0,0) !important;
    color: #000 !important;
    border: none;
  }
  .liste-article{
    margin-bottom: 25px;
  }
  .liste-article .list-article-bloc{
    position: relative;
    width: 100% !important;
    text-align: center;
    height: 100%;
  }
  .liste-article .ref{
    position: relative;
    text-align: right;
    margin-top: 15px;
  }
  .liste-article .ref p{
    position: relative;
    top: 50%;
    z-index: 2;
    color: #000 !important;
    font-size: 14px;
    height: 30px;
    padding-top: 5px;
  }
  .liste-article p{
    color: #413e3e;
  }
  .liste-article .gray-light{
    color: #9F9F9F;
    font-size: 0.8em;
    height: max-content;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin: 0;
  }
  .liste-article .image-article{
    max-height: 180px;
    transition: 0.5s;
    text-align: center;
  }
  .liste-article span{
    color: #9F9F9F !important;
  }
  .rectangle-article{
    position: absolute;
    z-index: 1;
    right: 0;
    height: 30px;
  }
  .headerArticle{
    position: relative;
    height: 240px;
    min-height: 240px;
    max-height: 240px;
  }
  .headerArticle img{
    position: absolute;
    top: 0;
    left: 0;
  }
  .headerArticle div{
    position:relative;
    padding: 25px;
    z-index: 2;
    color: #fff;
    display: flex;
    justify-content: space-between;
    top: 50%;
    transform: translateY(-50%);
  }
  .back-btn{
    position: relative;
    color: #7A7A7A;
    padding: 25px;
  }
  .back-btn button{
    text-decoration: none;
    color: #000;
  }
  .back-btn button:hover{
    color: #000;
    text-decoration: none;
  }
  .description-distance{
    margin-top: 50px !important;
    margin-bottom: 25px;
  }
  
  .article-taille ul{
    list-style: none;
    padding-left:0px !important;
    flex-flow: row wrap;
  }
  .article-taille ul li{
    border: solid #A4A4A4 1px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 20px;
    margin-top: 20px;
    width: max-content;
    min-width: 50px;
    height: 50px;
    color: #A4A4A4;
  }
  .similaires-article-bloc{
    position: relative;
    border: solid #9D9D9D 1px;
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 25px;
    margin-top: 25px;
    height: 100%;
    min-height: 350px;
    max-height: 350px;
  }
  .similaires-article-bloc img{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
  }
  .similaires-article-bloc div{
    padding-left: 20px;
    padding-right: 20px;
  }
  .jaune{
    color: #FFD102;
    margin-bottom: 20px;
  }
  .gris{
    color:#7A7A7A;
    margin-bottom: 20px;
  }
  .mention-legale {
    margin-bottom: 150px;
  }
  .mention-legale p {
    color:#7A7A7A;
    text-align: justify;
  }
  .mention-informations h5{
    font-weight: bold;
  }
  
  .contact{
    border: 1px solid #DCDCDC;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 20px;
  padding-top: 40px;
  height: 100%;
  }
  .contact-formulaire{
   background-color: #F7F9FB;
  }
  .contact h3{
    color:#7A7A7A;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .contact span{
    color:#7A7A7A;
  }
  .contact-info div{
    padding: 2px;
  }
  
  .contact-icon{
    width: 20%;
  }
  .contact-right{
    width: 80%;
  }
  .contact-icon img{
    width: 80% !important;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .contact-icon .icon-footer{
    width: 50% !important;
  }
  .contact-formulaire input, textarea{
    width: 100%;
    border: 1px solid #A8A8A8;
    box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  padding-left: 15px;
  margin-bottom: 20px;
  }
  .contact-formulaire textarea{
    height: 150px;
  }
  .contact-formulaire button{
    background: #FFD102;
    border-radius: 20px;
    position: relative;
    width: 200px;
    left: 100%;
    transform: translateX(-100%);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .contact-formulaire button span{
    color: #fff !important;
  }
  .contact-formulaire button:hover{
    background: #18C0F5;
  }
  .contact-map{
    padding: 0 !important;
    height: 100%;
  }
  
  .contact-map iframe{
    height: 100%;
    border-radius: 20px;
    min-height: 400px;
  }
  
  .carousel .thumb{
    height: 80px !important;
  }
  .carousel .slide{
  background-color: rgba(0,0,0,0) !important;
  }
  
  .carousel-status{
    display: none !important;
  }
  .carousel.carousel-slider .control-arrow{
    background-color: none !important;
  }
  .carousel.carousel-slider .control-arrow:hover {
    background:none !important;
    background-color: none !important;
  }
  
  .carousel .control-next.control-arrow:before {
    border-left: 12px solid #000 !important;
  }
  
  .carousel .control-prev.control-arrow:before {
    border-right: 12px solid #000 !important;
  }
  
  .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
  }
  
  .carousel .thumb.selected, .carousel .thumb:hover {
    border: 1px solid lightgray !important;
  }
  
  .filter-hover{
    position: absolute;
    background-color: rgba(255,98,17,1);
    top: 0;
    right: 0;
    height: 0%;
    width: 0%;
    z-index: 5;
    transition: 0.5s;
  }
  .list-article-bloc:hover .filter-hover{
    width: 50px;
    height: 50px;
  }
  .similaires-article-bloc .filter-hover{
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 0 !important;
  }
  .similaires-article-bloc:hover .filter-hover{
    width: 25% !important;
    height: 15% !important;
  }
  .similaires-article-bloc img{
    transition: 0.5s;
  }
  .similaires-article-bloc{
    overflow: hidden;
  }
  .similaires-article-bloc:hover img{
    transform: scale(1.1) translateX(-50%);
  }
  .list-article-bloc:hover .image-article{
    transform: scale(1.1);
  }
  .filter-hover svg{
    width: 20%;
    height: 20%;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .carousel .control-dots .dot{
    display: none !important;
  }
  
  .container-navigation{
    display: flex !important;
    justify-content: flex-end !important;
  }
  .accordion{
    margin-bottom: 50px !important;
  }
  .filtet-title .desktop{
    width: 100% !important;
  }
  .card-header{
    background-color: #fff !important;
  }
  .card{
    border: none !important
  }
  .facebook:hover a svg{
    color: #3b5998 !important;
  }
  .linkedin:hover img{
    color: #0e76a8 !important;
  }
  .pageErreur h1{
    color: #FFD102;
    font-size: 6em;
    margin-bottom: 25px;
  }
  .pageErreur h4{
    color: #7A7A7A;
    margin-bottom: 25px;
  }
  .pageErreur span{
    color: #7A7A7A;
  }
  .pageErreur p{
    color: #7a7a7a;
    font-weight: bold;
    font-size: 1.1em;
    margin-top: 25px;
  }
  .pageErreur ul {
    list-style: none;
    padding: 0 !important;
  }
  .pageErreur ul a{
    text-decoration: none !important;
    color: #FFD102 !important;
  }
  
  .liens-utils a {
    text-decoration: none !important;
  }
  .liens-utils a:hover {
    color: #18C0F5 !important;
  }
  .col-contact{
    margin-bottom: 25px;
    text-align: left !important;
  }
  @media (max-width: 576px) {
    .row-contact .col-contact{
      margin-top: 50px;
      text-align: left !important;
    }
    .carousel-Accueil{
      display: none !important;
    }
    .footer-contact-info{
      display: none !important;
    }
    .logo img {
      width: auto !important;
      position: relative !important;
      top: 100% !important;
      transform: translateY(-100%) !important;
      margin-bottom: -63px !important;
      margin-top: 0px !important;
    }
    .footer-item{
      margin-bottom: 15px;
    }
    .footer{
      padding-top: 25px;
    }
    .presentation{
      flex-direction: column-reverse;
    }
    .container-navigation{
      justify-content: flex-start !important;
    }
    .headerArticle{
      display: none !important;
    }
    .Breadcrumb{
      flex-direction: column;
    }
    .searchBar{
      width: 100% !important;
    }
    .filtet-title div div{
      width: 100% !important;
    }
    .navigation{
      height: 100% !important;
    }
    .desktop{
      display: none;
    }
  
    .filter{
      padding: 0 !important;
    }
  
    .filter li{
      width: 100% !important;
    }
    .no-padding{
      padding: 0 !important;
    }
    .mobile{
      margin-bottom: 50px !important;
    }
    .liens-utils a {
      font-size: 20px !important;
      margin: 5px;
    }
    .info-top-section{
      flex-direction: column;
    }
    .liens-utils{
      flex-direction: column;
    }
    .liens-utils .liens-utils-seconde-col{
      margin-left: 0px !important;
    }
    .inscription-newsleter-pop-up{
      height: 400px !important;
      width: 100% !important;
    }
   }
  
  @media (max-width: 768px) {
    .navigation{
      height: 100% !important;
    }
    .carousel-Accueil{
      display: none !important;
    }
    .container-navigation{
      justify-content: flex-start !important;
    }
    .presentation{
      flex-direction: column-reverse;
    }
    .footer-contact-info{
      display: none !important;
    }
    .footer-item{
      margin-bottom: 25px;
    }
    .footer{
      padding-top: 25px;
    }
    .inscription-newsleter-pop-up{
      height: 500px;
      width: 90%;
    }
    .facebook img{
      width: 100% !important;
    }
    .linkedin img{
      width: 100% !important;
    }
    .f-end {
      justify-content: flex-start;
    }
   }
  
  @media (max-width: 992px) {
    .navigation{
      height: 100% !important;
    }
    .container-navigation{
      justify-content: flex-start !important;
    }
    .bloc-chiffre{
      width: 100%;
    }
    .footer-contact-info{
      background-color: #fa5d0c ;
      padding-right: 15px;
      padding-left: 15px;
      border-radius: 15px;
    }
    .image-bloc-orange{
      display: none !important;
    }
    .footer-contact-info p{
      width: auto !important;
    }
    .center-vertical{
      text-align: center !important;
    }
    .full-height{
      height: 100% !important;
    }
    .presentation{
      flex-direction: column-reverse;
    }
    .pageErreur{
      margin-bottom: 80px;
    }
   }
  
  @media (min-width: 1200px) {
    .mobile{
      display: none;
    }
  
   }
  
   @keyframes example {
    from {
      height: 70%;
      left: 0;
      top: 120%;
    }
    
    to {
      height: 40%;
      left: 50%;
      top: 50%;
    }
  }
  .input-custume{
    position: relative;
    height: 50px !important;
    padding: 0 !important;
    background-color: #fff !important;
    width: max-content;
  }
  .input-custume input{
    position: relative;
    height: 100%;
    border: none !important;
    background-color: rgba(0,0,0,0);
    padding: 5px !important;
    padding-left: 10px !important;
  }
  .input-custume button{
    position: absolute;
    top: 0;
    height: 100% !important;
    text-decoration: none !important;
    border: none !important;
    background-color: #18C0F5;
    padding: 5px !important;
    min-width: 80px !important;
    overflow: hidden;
  }
  .input-custume img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 40%;
    color: #fff;
  }
  
  .input-custume button:hover img{
    animation-name: example;
    animation-duration: 0.5s;
  }
  .confiance img{
    margin-top: 50px;
  }
  .skeleton span {
    position: relative;
    left: 100%;
    transform: translateX(-100%) scale(1, 0.60);
  }
  .inscription-newsleter-pop-up{
    display: none; 
    color: #2a2c30;
    text-align: center;
    z-index: 3000;
    position: fixed;
    height: 500px;
    width: 60%;
    background-size: 100% 100%;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 20px;
    border-radius: 10px;
    -moz-box-shadow: 1px 2px 5px 0px #000000;
    -webkit-box-shadow: 1px 2px 5px 0px #000000;
    -o-box-shadow: 1px 2px 5px 0px #000000;
    box-shadow: 1px 2px 5px 0px #000000;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#000000, Direction=171, Strength=5);
    -webkit-animation: notifPopUp 3s; /* Safari 4+ */
  -moz-animation:    notifPopUp 3s; /* Fx 5+ */
  -o-animation:      notifPopUp 3s; /* Opera 12+ */
  animation:         notifPopUp 3s; /* IE 10+, Fx 29+ */
  }
  .inscription-pop-up{
    position: absolute;
    width: 38% !important;
    right: 5% !important;
    top: 90%;
    transform: translateY(-100%) !important;
  
  }
  .inscription{
    padding: 20px;
    border-radius: 10px;
    background-color: #000000;
    text-align: center;
    width: 105%;
    transform: translateX(-2.5%);
  
  }
  .inscription input{
    width: 95%;
    height: 35px;
    margin-bottom: 5px;
    display: block;
    border-radius: 10px;
    border: solid 0px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  font-family: 'Fira Sans', sans-serif;
  }
  .fermer-newsletter{
    position: absolute !important;
    top: 10px;
    right: 15px;
    cursor: pointer !important;
  }
  .inscription button{
    color: #2a2c30;
    background-color: #ffea2a;
    width: 95%;
    height: 35px;
    display: block;
    border-radius: 10px;
    border: solid 0px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    -moz-box-shadow: 1px 3px 5px 0px #222427;
    -webkit-box-shadow: 1px 3px 5px 0px #222427;
    -o-box-shadow: 1px 3px 5px 0px #222427;
    box-shadow: 1px 3px 5px 0px #222427;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#222427, Direction=171, Strength=5);
    cursor: pointer;
  }