@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Montserrat', sans-serif;
}

p, a, span, button, label{
  font-family: 'Fira Sans', sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

a, p, span {
  font-family: 'Fira Sans', sans-serif;
}


.round-button{
  padding: 5px;
  background-color: #2EB0FA;
  width: -webkit-min-content;
  width: min-content;
  border-radius: 50px;
  color:#fff
}
.info-top-section{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.navigation .dropdown-toggle {
  color: #fff !important;
}

.bloc-chiffre{
  padding: 15px;
  width: 98%;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: 1s;
  overflow: hidden;
}

@-webkit-keyframes in {
  from {
    bottom: 0 ;
    height: 0%;
  }
  
  to {
    bottom: 0;
    height: 100%;
  }
}

@keyframes in {
  from {
    bottom: 0 ;
    height: 0%;
  }
  
  to {
    bottom: 0;
    height: 100%;
  }
}

@-webkit-keyframes out {
  from {
    top: 0;
    height: 100%;
  }
  
  to {
    top: 0;
    height: 0%;
  }
}

@keyframes out {
  from {
    top: 0;
    height: 100%;
  }
  
  to {
    top: 0;
    height: 0%;
  }
}

.filter-chiffre-clef {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  width: 100%;
  height: 0%;
  background-color: #ff5e14;
  transition: 1s;
  -webkit-animation-name: out;
          animation-name: out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
.info-chiffre-clef{
  transition: 1s;
}

.bloc-chiffre span{
  font-style: normal;
font-weight: 900;
font-size: 45px;
line-height: 50px;
/* identical to box height */

-webkit-align-items: center;

        align-items: center;
text-align: center;

color: #484848;
}
.bloc-chiffre p{
  margin-bottom: 15px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 64px;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;

  color: #484848;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.flexBox {
  display: -webkit-flex;
  display: flex;
}
.flexBox-col{
  -webkit-flex-direction: column;
          flex-direction: column;
}
.presentation{
  height: 100%;
}
.presentation-text{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  height: 100%;
  min-height: 350px;
}
.presentation-text h1{
  margin-bottom: 25px;
  color: #2EB0FA;
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  line-height: 45px;
  -webkit-align-items: center;
          align-items: center;

  color: #2EB0FA;
}
.presentation-text .logo-client{
  display: -webkit-flex;
  display: flex;
}
.presentation-text .logo-client div{
  width: 50%;
  position: relative;
}
.presentation-text .logo-client img{
  width: 90%;
  height: auto !important;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.presentation-text p{
  text-align: justify;
  margin-bottom: 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;
  -webkit-align-items: center;
          align-items: center;
  text-align: justify;
}
.presentation-icon h5{
  font-weight: bold;
  margin-bottom: 10px;
}
.presentation p{
  color: #ACACAC;
}
.icon-presentation{
  height: 80px;
  width: 80px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-bottom: 10px;
}
.center-text{
  text-align: center;
}
.f-start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.f-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.f-around {
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.f-between {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.f-center{
  -webkit-justify-content: center;
          justify-content: center;
}
.pagination nav{
  width: 100%;
}
.pagination ul{
  width: -webkit-max-content;
  width: max-content;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.icon {
  font-size: 20px;
  margin-right: 15px;
  color: #fff !important;
}
p{
  margin-right: 15px;
}
.w10 {
  width: 10%;
}
.w20 {
  width: 20%;
}
.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50% !important;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w80 {
  width: 80%;
}
.w90 {
  width: 90%;
}
.w100 {
  width: 100%;
}
.center {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.right{
  text-align: right;
}
.left{
  text-align: left;
}
a{
  text-decoration: none;
}
.bloc {
  padding-top: 70px;
  padding-bottom: 70px;
}
.big-bloc{
  padding-top: 100px;
  padding-bottom: 100px;
}
.min-bloc {
  padding:15px;
}
.no-padding{
  padding:0;
  margin-bottom: 0;
}
.square{
  position: relative;
  top: 0;
  left: 0;
  height: 80px !important;
}
.square a{
  position: relative;
  height: 100% !important;
}
.navigation-link{
  position: relative;
  height: 100%;
}
.nav-link{
  margin-right: 70px;
}
.navigation-link a {
  position: relative;
  color:#9D9D9D !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 58px;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  text-transform: uppercase;
  height: 100%;
}
.navigation-link a:hover {
  border-bottom: solid 3px #2EB0FA ;
}
.absolue{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.center-bottom{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.carousel-control-next, .carousel-control-prev{
  width: 5% !important;
}
.carousel-caption{
  width: 90% !important;
  left: 5% !important;
}
.carousel-inner{
  height: 100%;
}
.header-carousel-bgImage{
  width: 100% !important;
}
.bold {
  font-weight: bold !important;
}
.variant-h3{
  font-size: 30px;
}
.caption-text h5{
  margin-bottom: 25px;
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    -webkit-filter: invert(100%);
            filter: invert(100%);
}
.footer{
  padding-top: 150px;
  padding-bottom: 0px !important;
}
.footer a{
  text-decoration: none;
  color: #fff;
}
.footer-contact-info{
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-55%);
          transform: translateX(-50%) translateY(-55%);
}
input[type="text"]:focus {
  outline: none;
  box-shadow: 0px 0px 5px #fff;
  border:1px solid #fff;
}
input[type="text"]:focus:hover {
  outline: none;
  box-shadow: 0px 0px 5px #fff;
  border:1px solid #fff;
  border-radius:0;
}
.dropdown-toggle::after{
  color: #fff !important;
}
.bloc-orange{
  position: absolute;
  top: 0;
}
.bloc-orange-negative{
  margin-left: -25px;
}
.bloc-footer-icon{
  width: 35px;
  position: relative;
  left: 50%;
  top:50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}
.center-vertical{
  position: relative;
  top: 50%;
  height: -webkit-max-content;
  height: max-content;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.center-vertical p{
  font-size: 15px;
}

.ref-client{
  color: #2EB0FA !important;
  text-decoration: underline !important;
}
.social-footer div{
  padding: 15px;
  border-left: 1px solid #fff;
}
.social-footer div img{
  width: 80%;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
}
.social-footer .linkedin {
  border-right: 1px solid #fff;

}
.breadcrumb{
  background-color: #fff !important;
}
.filter{
  list-style: none;
}
.sticky{
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  z-index: 1000;
}
.filter .active{
  background-color: #2EB0FA;
}
.filter .active{
  color: #fff !important;
  font-weight: bold;
}
.filter li{
  position: relative;
  height: -webkit-max-content;
  height: max-content;
  padding: 12px;
  width: 110%;
  cursor: pointer;
}
.filter a{
  position: relative;
  text-decoration: none !important;
  color: #000;
}
.filtet-title{
  position: relative;
}
.filtet-title h2{
  font-size: 25px !important;
  color: #565656 !important;
  font-weight: bold;
}
.filtet-title div div{
  width: 50% !important;
}
#customeToggle{
  background-color: rgba(0,0,0,0) !important;
  color: #000 !important;
  border: none;
}
.liste-article{
  margin-bottom: 25px;
  overflow: hidden;
}
.liste-article .list-article-bloc{
  position: relative;
  width: -webkit-max-content;
  width: max-content;
  max-width: 200px;
  text-align: center;
  height: 100%;
  overflow: hidden;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.liste-article .ref{
  position: relative;
  text-align: right;
  margin-top: 15px;
}
.liste-article .ref p{
  position: relative;
  top: 50%;
  z-index: 2;
  color: #fff !important;
  font-size: 14px;
  height: 30px;
  padding-top: 5px;
}
.liste-article p{
  color: #9F9F9F
}
.liste-article .image-article{
  max-height: 180px;
  max-width: 100%;
  transition: 0.5s;
  text-align: center;
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}
.liste-article span{
  color: #9F9F9F !important
}
.rectangle-article{
  position: absolute;
  z-index: 1;
  right: 0;
  height: 30px;
}
.headerArticle{
  position: relative;
  height: 240px;
  min-height: 240px;
  max-height: 240px;
}
.headerArticle img{
  position: absolute;
  top: 0;
  left: 0;
}
.headerArticle div{
  position:relative;
  padding: 25px;
  z-index: 2;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.back-btn{
  position: relative;
  color: #7A7A7A;
  padding: 25px;
}
.back-btn button{
  text-decoration: none;
  color: #000;
}
.back-btn button:hover{
  color: #000;
  text-decoration: none;
}
.description-distance{
  margin-top: 50px !important;
  margin-bottom: 25px;
}
.article-descripton{
  font-family: 'Fira Sans', sans-serif;
  font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.article-taille ul{
  list-style: none;
  padding-left:0px !important;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
}
.article-taille ul li{
  border: solid #A4A4A4 1px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 20px;
  margin-top: 20px;
  width: -webkit-max-content;
  width: max-content;
  min-width: 50px;
  height: 50px;
  color: #A4A4A4;
}
.similaires-article-bloc{
  position: relative;
  border: solid #9D9D9D 1px;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  margin-top: 25px;
  height: 100%;
  min-height: 370px;
  max-height: 370px;
}
.similaires-article-bloc img{
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.similaires-article-bloc div{
  padding-left: 20px;
  padding-right: 20px;
}
.jaune{
  color: #2EB0FA;
  margin-bottom: 20px;
}
.gris{
  color:#7A7A7A;
  margin-bottom: 20px;
}
.mention-legale {
  margin-bottom: 150px;
}
.mention-legale p {
  color:#7A7A7A;
  text-align: justify;
}
.mention-informations h5{
  font-weight: bold;
}

.contact{
  border: 1px solid #DCDCDC;
box-sizing: border-box;
border-radius: 20px;
padding: 20px;
padding-top: 40px;
height: 100%;
}
.contact-formulaire{
 background-color: #F7F9FB;
}
.contact h3{
  color:#7A7A7A;
  font-weight: bold;
  margin-bottom: 20px;
}
.contact span{
  color:#7A7A7A;
}
.contact-info div{
  padding: 2px;
}

.contact-icon{
  width: 20%;
}
.contact-right{
  width: 80%;
}
.contact-icon img{
  width: 80% !important;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.contact-formulaire input, textarea{
  width: 100%;
  border: 1px solid #A8A8A8;
  box-sizing: border-box;
border-radius: 5px;
padding: 5px;
padding-left: 15px;
margin-bottom: 20px;
}
.contact-formulaire textarea{
  height: 150px;
}
.contact-formulaire button{
  background: #2EB0FA;
  border-radius: 20px;
  position: relative;
  width: 200px;
  left: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  margin-top: 20px;
  margin-bottom: 20px;
}
.contact-formulaire button span{
  color: #fff !important;
}
.contact-formulaire button:hover{
  background: #2EB0FA;
}
.contact-map{
  padding: 0 !important;
  height: 100%;
}

.contact-map iframe{
  height: 100%;
  border-radius: 20px;
  min-height: 400px;
}

.carousel .thumb{
  height: 80px !important;
}
.carousel .slide{
background-color: rgba(0,0,0,0) !important;
}

.carousel-status{
  display: none !important;
}
.carousel.carousel-slider .control-arrow{
  background-color: none !important;
}
.carousel.carousel-slider .control-arrow:hover {
  background:none !important;
  background-color: none !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 12px solid #000 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 12px solid #000 !important;
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 1px solid lightgray !important;
}

.filter-hover{
  position: absolute;
  background-color: rgba(46,176,250,1);
  top: 0;
  right: 0;
  height: 0%;
  width: 0%;
  z-index: 5;
  transition: 0.5s;
}
.produit-affiche:hover .filter-hover{
  width: 45px;
  height: 45px;
}
.list-article-bloc:hover .filter-hover{
  width: 45px;
  height: 45px;
}
.similaires-article-bloc .filter-hover{
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 0 !important;
}
.similaires-article-bloc:hover .filter-hover{
  width: 25% !important;
  height: 15% !important;
}
.similaires-article-bloc img{
  transition: 0.5s;
}
.similaires-article-bloc{
  overflow: hidden;
}
.similaires-article-bloc:hover img{
  -webkit-transform: scale(1.1) translateX(-50%);
          transform: scale(1.1) translateX(-50%);
}
.list-article-bloc:hover div .image-article{
  -webkit-transform: scale(1.1) translateX(-50%) translateY(-50%);
          transform: scale(1.1) translateX(-50%) translateY(-50%);
}
.filter-hover svg{
  width: 40px;
  height: 40px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
}
.carousel .control-dots .dot{
  display: none !important;
}

.container-navigation{
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}
.accordion{
  margin-bottom: 50px !important;
}
.filtet-title .desktop{
  width: 100% !important;
}
.card-header{
  background-color: #fff !important;
}
.card{
  border: none !important
}
.facebook:hover a svg{
  color: #3b5998 !important;
}
.linkedin:hover img{
  color: #0e76a8 !important;
}
.pageErreur h1{
  color: #2EB0FA;
  font-size: 6em;
  margin-bottom: 25px;
}
.pageErreur h4{
  color: #7A7A7A;
  margin-bottom: 25px;
}
.pageErreur span{
  color: #7A7A7A;
}
.pageErreur p{
  color: #7a7a7a;
  font-weight: bold;
  font-size: 1.1em;
  margin-top: 25px;
}
.pageErreur ul {
  list-style: none;
  padding: 0 !important;
}
.pageErreur ul a{
  text-decoration: none !important;
  color: #2EB0FA !important;
}

.liens-utils a {
  text-decoration: none !important;
}
.liens-utils a:hover {
  color: #2EB0FA !important;
}
.col-contact{
  margin-bottom: 25px;
}

@media (max-width: 576px) {
  .column-mobile{
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  #pack-title h2{
  font-size: 42px !important;    
  }
  #pack-title h3{
    font-size:22px !important ;
  }
  .display-mobile-none{
    display: none;
  }
  .pack:hover{
    -webkit-transform: translateX(-50%) scale(1.1) !important;
            transform: translateX(-50%) scale(1.1) !important;
    z-index: 2;
  }
  .row-contact .col-contact{
    margin-top: 50px;
  }
  .carousel-Accueil{
    display: none !important;
  }
  .footer-contact-info{
    display: none !important;
  }
  .logo img {
    width: auto !important;
    position: relative !important;
  }
  .footer-item{
    margin-bottom: 15px;
  }
  .footer{
    padding-top: 25px;
  }
  .presentation{
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .container-navigation{
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .headerArticle{
    display: none !important;
  }
  .Breadcrumb{
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .searchBar{
    width: 100% !important;
  }
  .filtet-title div div{
    width: 100% !important;
  }
  .navigation{
    height: 100% !important;
    border: none !important;
    padding: 15px !important;
    top: 0 !important;
  }
  .desktop{
    display: none;
  }

  .filter{
    padding: 0 !important;
  }

  .filter li{
    width: 100% !important;
  }
  .no-padding{
    padding: 0 !important;
  }
  .mobile{
    margin-bottom: 50px !important;
  }
  .liens-utils a {
    font-size: 20px !important;
    margin: 5px;
  }
  .info-top-section{
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .info-top-section .flexBox{
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .infoSection{
    border-bottom: 1px solid #7A7A7A;
  }
  .liens-utils{
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .liens-utils .liens-utils-seconde-col{
    margin-left: 0px !important;
  }
  .inscription-newsleter-pop-up{
    height: 400px !important;
    width: 100% !important;
  }
  .chiffre-bloc {
   background-image: none !important;
   background-color: #2EB0FA;
  }
  .square{
    margin-bottom: 30px;
  }
  .nav-link{
    margin-right: 0px;
  }
  .change-xs-sm{
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
 }

@media (max-width: 768px) {
  .display-mobile-none{
    display: none;
  }
  .navigation{
    height: 100% !important;
    border: none !important;
    padding: 15px !important;
    top: 0 !important;
  }
  .carousel-Accueil{
    display: none !important;
  }
  .container-navigation{
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .presentation{
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .footer-contact-info{
    display: none !important;
  }
  .footer-item{
    margin-bottom: 25px;
  }
  .footer{
    padding-top: 25px;
  }
  .inscription-newsleter-pop-up{
    height: 500px;
    width: 90%;
  }
  .facebook img{
    width: 100% !important;
  }
  .linkedin img{
    width: 100% !important;
  }
  .f-end {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .chiffre-bloc {
    background-image: none !important;
    background-color: #2EB0FA;
   }
   .change-xs-sm{
     -webkit-justify-content: center !important;
             justify-content: center !important;
   }
 }

@media (max-width: 992px) {
  .display-mobile-none{
    display: none;
  }
  .navigation{
    height: 100% !important;
    border: none !important;
    padding: 15px !important;
    top: 0 !important;
  }
  .container-navigation{
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .bloc-chiffre{
    width: 100%;
  }
  .footer-contact-info{
    background-color: #2EB0FA ;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 15px;
  }
  .image-bloc-orange{
    display: none !important;
  }
  .footer-contact-info p{
    width: auto !important;
  }
  .center-vertical{
    text-align: center !important;
  }
  .full-height{
    height: 100% !important;
  }
  .presentation{
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .pageErreur{
    margin-bottom: 80px;
  }
  .md-carousel-small{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 20px;
  }
  .md-carousel-small a{
    width: 49%;
  }
  .md-carousel-small a img{
    width: 100% !important;
    height: auto !important;
    max-width: 100% !important;
  }
  .pack{
    width: 99% !important;
  }
  .no-pad{
    padding: 0 !important;
  }
 }

@media (min-width: 1200px) {
  .mobile{
    display: none;
  }

 }

 @-webkit-keyframes example {
  from {
    height: 70%;
    left: 0;
    top: 120%;
  }
  
  to {
    height: 40%;
    left: 50%;
    top: 50%;
  }
}

 @keyframes example {
  from {
    height: 70%;
    left: 0;
    top: 120%;
  }
  
  to {
    height: 40%;
    left: 50%;
    top: 50%;
  }
}
.input-custume{
  position: relative;
  height: 50px !important;
  padding: 0 !important;
  background-color: #fff !important;
  width: -webkit-max-content;
  width: max-content;
}
.input-custume input{
  position: relative;
  height: 100%;
  border: none !important;
  background-color: rgba(0,0,0,0);
  padding: 5px !important;
  padding-left: 10px !important;
}
.input-custume button{
  position: absolute;
  top: 0;
  height: 100% !important;
  text-decoration: none !important;
  border: none !important;
  background-color: #2EB0FA;
  padding: 5px !important;
  min-width: 80px !important;
  overflow: hidden;
}
.input-custume img{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  height: 40%;
  color: #fff;
}

.input-custume button:hover img{
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}
.confiance img{
  margin-top: 50px;
}
.skeleton span {
  position: relative;
  left: 100%;
  -webkit-transform: translateX(-100%) scale(1, 0.60);
          transform: translateX(-100%) scale(1, 0.60);
}
.inscription-newsleter-pop-up{
  display: none; 
  color: #2a2c30;
  text-align: center;
  z-index: 3000;
  position: fixed;
  height: 500px;
  width: 60%;
  background-size: 100% 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  padding: 20px;
  border-radius: 10px;
  -o-box-shadow: 1px 2px 5px 0px #000000;
  box-shadow: 1px 2px 5px 0px #000000;
  filter:progid:DXImageTransform.Microsoft.Shadow(color=#000000, Direction=171, Strength=5);
  -webkit-animation: notifPopUp 3s; /* Safari 4+ */ /* Fx 5+ */ /* Opera 12+ */
animation:         notifPopUp 3s; /* IE 10+, Fx 29+ */
}
.inscription-pop-up{
  position: absolute;
  width: 38% !important;
  right: 5% !important;
  top: 90%;
  -webkit-transform: translateY(-100%) !important;
          transform: translateY(-100%) !important;

}
.inscription{
  padding: 20px;
  border-radius: 10px;
  background-color: #000000;
  text-align: center;
  width: 105%;
  -webkit-transform: translateX(-2.5%);
          transform: translateX(-2.5%);

}
.inscription input{
  width: 95%;
  height: 35px;
  margin-bottom: 5px;
  display: block;
  border-radius: 10px;
  border: solid 0px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  font-family: 'Fira Sans', sans-serif;
}
.fermer-newsletter{
  position: absolute !important;
  top: 10px;
  right: 15px;
  cursor: pointer !important;
}
.inscription button{
  color: #2a2c30;
  background-color: #2EB0FA;
  width: 95%;
  height: 35px;
  display: block;
  border-radius: 10px;
  border: solid 0px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  -o-box-shadow: 1px 3px 5px 0px #222427;
  box-shadow: 1px 3px 5px 0px #222427;
  filter:progid:DXImageTransform.Microsoft.Shadow(color=#222427, Direction=171, Strength=5);
  cursor: pointer;
}
.carousel-Accueil .header-content{
  position:relative;
  width: -webkit-max-content;
  width: max-content;
}
.carousel-Accueil .header-content h1{
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 120px;
  line-height: 159px;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  
  color: #2EB0FA;
}
.carousel-Accueil .header-content h3{
  font-family: 'Montserrat', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 64px;
line-height: 78px;
-webkit-align-items: center;
        align-items: center;
text-align: center;

color: #BEBEBE;
}
.carousel-Accueil .header-content .button{
  position: relative;
  width: 90%;
  background: #2EB0FA;
  border-radius: 21px;
  border: none;
  font-family: 'Fira Sans', sans-serif;  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 61px;
  text-align: center;
  padding: 15px;
  color: #FFFFFF;
}
.info-covid span {
  font-weight: bold;
}
.info-covid p {
  margin-bottom: 0px !important;
}
.info-covid button{
  background-color: rgba(0, 0, 0, 0);
  color: #000;
  border: 1px solid #7A7A7A;
  border-radius: 2px;
  font-weight: bold;
  padding: 10px;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
}
.bloc-nos-produit{
  background: #FFFFFF;
  box-shadow: 0px -3px 76px rgba(0, 0, 0, 0.11);
  border-radius: 25px;
  padding: 20px;
  padding-bottom: 40px;
  padding-top: 40px;
}
.produit-affiche{
  border: solid #DCDCDC 1px;
  width: 95%;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 100%;
  padding: 15px;
  margin-bottom: 25px;
  max-height: 400px;
  min-height: 400px;
  overflow: hidden;
}

.produit-affiche img {
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: auto;
  max-height: 250px;
  max-width: 100%;
  transition: 1s;
}
.produit-affiche:hover img{
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1.2);
          transform: translateX(-50%) translateY(-50%) scale(1.2);
}
.produit-affiche .produit-affiche-info{
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 90%;
}
.produit-affiche .produit-affiche-info .marque-produit {
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  /* identical to box height */

  -webkit-align-items: center;

          align-items: center;
  margin: 15px 0px 15px 0px;
  color: #9D9D9D;
}
.produit-affiche .produit-affiche-info .nom-produit {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  /* identical to box height */
  position: relative;
  -webkit-align-items: center;
          align-items: center;
  margin: 15px 0px 15px 0px;
  color: #2EB0FA;
  line-height: 1.5em !important;
  height: 3em !important;
  overflow: hidden;
}
.produit-affiche .produit-affiche-info .prix-produit {
  color: #565656 !important;
  text-align: right;
  font-weight: bold;

}
.produit-affiche .produit-affiche-info span{
  font-family: 'Fira Sans', sans-serif;
font-style: normal;
font-weight: 900;
font-size: 12px;
/* identical to box height */

-webkit-align-items: center;

        align-items: center;
margin: 15px 0px 15px 0px;
color: #9D9D9D;
}
.my-heading-two{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
 .pilier{
   position: relative;
   text-align: center;
   width: 95%;
   padding: 2px;
 }
 .pilier p{
   margin: 0;
 }
 .pilier img{
   position: relative;
   max-height: 100px;
 }
 .pilier h4{
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  
  color: #2EB0FA;
 }
 .pilier p {
   text-align: justify;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 25px;
  color: #ACACAC;
 }
 .col-bloc{
   margin-bottom: 15px;
 }
 .logo img {
  width: auto !important;
  position: relative !important;
  text-align: center;
}
.info-top-section{
  margin-right: 15px;
}
.chiffre-bloc {
  background-image: none !important;
  background-color: #2EB0FA;
 }
 .Capital {
   text-transform: capitalize;
 }
 .produit-name-reduce{
   line-height: 1.5em !important;
   height: 3em !important;
   overflow: hidden;
 }

 .md-carousel-small a img{
  width: auto;
  height: 100%;
  max-width: 100% !important;
}
.carousel img{
  height: 100%;
}


#pack-title h2{
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 106.19%;
  /* or 68px */
  
  text-align: center;
  text-transform: uppercase;
  
  color: #0E6D8B;
  
}
#pack-title{
margin-bottom: 80px;
}

#pack-title h3{
  font-style: normal;
font-weight: 300;
font-size: 36px;
line-height: 106.19%;
/* or 38px */

text-align: center;
letter-spacing: 0.72em;
text-transform: uppercase;
color: #0E6D8B;
margin-left: 20px;
}

.pack{
  border: solid #DFDFDF 1px;
  border-radius: 22px;
  position: relative;
  width: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%) scale(0.9);
          transform: translateX(-50%) scale(0.9);
  padding: 0;
  transition: 1s;
  margin-bottom: 35px;
  background-color: #fff;
}

.pack:hover{
  -webkit-transform: translateX(-50%) scale(1.3);
          transform: translateX(-50%) scale(1.3);
  z-index: 2;
}

.pack .pack-title{
  position: relative;
  background-size: 100% 100%;
  min-height: 80px;
  height: 80px;
}

.pack .pack-title h5{
  position: relative;
  top: 50% !important;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #fff;
  letter-spacing: 0.6rem;
}

.pack .pack-prix{
  position: inherit;
  height: 100px;
}
.pack .pack-prix div{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.pack .pack-prix p{
  font-weight: 300;
font-size: 32px;
line-height: 65%;
letter-spacing: 0.13em;
text-align: center;
color: #8B8B8B;
width: 100%;
margin: 0;
}

.pack .pack-prix span{
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 65%;
/* or 8px */
text-align: center;
letter-spacing: 0.13em;
color: #8B8B8B;
}

.pack .pack-info{
  background-color: rgba(30, 234, 26, 0.1);
  height: 180px;
}

.pack .pack-info ul{
  list-style: none;
  text-align: center;
  width: 100%;
  padding: 0;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

  
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 65%;
/* or 9px */

text-align: center;
letter-spacing: 0.13em;

color: #787878;
}
.pack .pack-info ul li{
  margin-bottom: 20px;
  font-family: 'Fira Sans', sans-serif;
}
.pack .pack-button{
  height: 110px;
}

.pack .pack-button button{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #1EEA1A;
border: 0px solid #1EEA1A;
box-sizing: border-box;
border-radius: 22px;
color: #fff;
width: 80%;
padding: 10px;
font-style: normal;
font-weight: normal;
font-size: 22px;
line-height: 28px;
/* identical to box height */

text-align: center;
letter-spacing: 0.06em;

color: #FFFFFF;
}

.pack-premium .pack-button button{
  background-color: #ffd700;
}
.pack-complet .pack-button button{
  background-color: #000;
}
.pack-standard .pack-button button{
  background-color: #1EC8ED;
}

.pack-complet .pack-info ul{
  background-color: rgba(0, 0, 0, 0.1);
}

.pack-standard .pack-info ul{
  background-color: rgba(30, 200, 237, 0.1);
}

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  h1, h2, h3, h4, h5, h6{
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }
  
  a, p, span {
  font-family: 'Fira Sans', sans-serif;
  }
  
  
  .round-button{
    padding: 5px;
    background-color: #ff5e14;
    width: -webkit-min-content;
    width: min-content;
    border-radius: 50px;
    color:#fff
  }
  .navigation a {
    color: #fff !important;
  }
  
  .bloc-chiffre{
    border: solid #fff 1px;
    padding: 15px;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 10px;
    width: 98%;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-bottom: 15px;
    margin-top: 15px;
    transition: 1s;
    overflow: hidden;
  }
  .bloc-chiffre img{
    margin-bottom: 15px;
    max-height: 90px;
  }
  
  @-webkit-keyframes in {
    from {
      bottom: 0 ;
      height: 0%;
    }
    
    to {
      bottom: 0;
      height: 100%;
    }
  }
  
  @keyframes in {
    from {
      bottom: 0 ;
      height: 0%;
    }
    
    to {
      bottom: 0;
      height: 100%;
    }
  }
  
  @-webkit-keyframes out {
    from {
      top: 0;
      height: 100%;
    }
    
    to {
      top: 0;
      height: 0%;
    }
  }
  
  @keyframes out {
    from {
      top: 0;
      height: 100%;
    }
    
    to {
      top: 0;
      height: 0%;
    }
  }
  
  .filter-chiffre-clef {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    width: 100%;
    height: 0%;
    background-color: #ff5e14;
    transition: 1s;
    -webkit-animation-name: out;
            animation-name: out;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
  .info-chiffre-clef{
    transition: 1s;
  }
  .bloc-chiffre:hover{
    border: solid #ff5e14 1px;
    margin-top: 0px;
  }
  .bloc-chiffre:hover .info-chiffre-clef{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .bloc-chiffre:hover .filter-chiffre-clef{
    height: 100%;
    -webkit-animation-name: in;
            animation-name: in;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }
  .bloc-chiffre:hover .center{
    background-color: #fff !important;
  }
  .bloc-chiffre span{
    font-size: 36px;
    font-weight: bold;
  }
  .bloc-chiffre p{
    margin-bottom: 15px;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      -webkit-animation: App-logo-spin infinite 20s linear;
              animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .flexBox {
    display: -webkit-flex;
    display: flex;
  }
  .flexBox-col{
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .presentation-text h1{
    margin-bottom: 25px;
    color: #FFD102;
  }
  .presentation-text p{
    text-align: justify;
    margin-bottom: 25px;
  }
  .presentation-icon h5{
    font-weight: bold;
    margin-bottom: 10px;
  }
  .presentation p{
    color: #ACACAC;
  }
  .icon-presentation{
    height: 80px;
    width: 80px;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-bottom: 10px;
  }
  .center-text{
    text-align: center;
  }
  .f-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .f-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
  .f-around {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }
  .f-between {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  .f-center{
    -webkit-justify-content: center;
            justify-content: center;
  }
  .pagination nav{
    width: 100%;
  }
  .pagination ul{
    width: -webkit-max-content;
    width: max-content;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .icon {
    font-size: 20px;
    margin-right: 15px;
    color: #fff !important;
  }
  p{
    margin-right: 15px;
  }
  .w10 {
    width: 10%;
  }
  .w20 {
    width: 20%;
  }
  .w30 {
    width: 30%;
  }
  .w40 {
    width: 40%;
  }
  .w50 {
    width: 50% !important;
  }
  .w60 {
    width: 60%;
  }
  .w70 {
    width: 70%;
  }
  .w80 {
    width: 80%;
  }
  .w90 {
    width: 90%;
  }
  .w100 {
    width: 100%;
  }
  .center {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .right{
    text-align: right;
  }
  .left{
    text-align: left;
  }
  a{
    text-decoration: none;
  }
  .bloc {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .big-bloc{
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .min-bloc {
    padding:15px;
  }
  .no-padding{
    padding:0;
    margin-bottom: 0;
  }
  .square{
    position: relative;
    top: 0;
    left: 0;
    height: 65px;
  }
  .square a{
    height: 100% !important;
  }
  .absolue{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .center-bottom{
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .carousel-control-next, .carousel-control-prev{
    width: 5% !important;
  }
  .carousel-caption{
    width: 90% !important;
    left: 5% !important;
  }
  .bold {
    font-weight: bold !important;
  }
  .variant-h3{
    font-size: 30px;
  }
  .caption-text h5{
    margin-bottom: 25px;
  }
  
  .carousel-control-next,
  .carousel-control-prev /*, .carousel-indicators */ {
      -webkit-filter: invert(100%);
              filter: invert(100%);
  }
  .footer{
    padding-top: 150px;
    text-align: left !important;
  }
  .footer a{
    text-decoration: none;
    color: #fff;
  }
  .footer-contact-info{
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }
  input[type="text"]:focus {
    outline: none;
    box-shadow: 0px 0px 5px #fff;
    border:1px solid #fff;
  }
  input[type="text"]:focus:hover {
    outline: none;
    box-shadow: 0px 0px 5px #fff;
    border:1px solid #fff;
    border-radius:0;
  }
  .dropdown-toggle::after{
    color: #fff !important;
  }
  .bloc-orange{
    position: absolute;
    top: 0;
  }
  .bloc-orange-negative{
    margin-left: -25px;
  }
  .bloc-footer-icon{
    width: 35px;
    position: relative;
    left: 50%;
    top:50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }
  .center-vertical{
    position: relative;
    top: 50%;
    height: -webkit-max-content;
    height: max-content;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .center-vertical p{
    font-size: 14px;
  }
  
  .ref-client{
    color: #FFD102 !important;
    text-decoration: underline !important;
  }
  .social-footer div{
    padding: 15px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    padding-right: 20px;
  }
  .social-footer div img{
    width: 80%;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
  }
  .social-footer .linkedin {
    border-right: 1px solid #fff;
  
  }
  .breadcrumb{
    background-color: #fff !important;
  }
  .filter{
    list-style: none;
  }
  .sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    z-index: 1000;
  }
  .filter .active{
    background-color: #FFD102;
  }
  .filter .active{
    color: #fff !important;
    font-weight: bold;
  }
  .filter li{
    position: relative;
    height: -webkit-max-content;
    height: max-content;
    padding: 12px;
    width: 110%;
    cursor: pointer;
  }
  .filter a{
    position: relative;
    text-decoration: none !important;
    color: #000;
  }
  .filtet-title{
    position: relative;
  }
  .filtet-title h2{
    font-size: 25px !important;
    color: #565656 !important;
    font-weight: bold;
  }
  .filtet-title div div{
    width: 50% !important;
  }
  #customeToggle{
    background-color: rgba(0,0,0,0) !important;
    color: #000 !important;
    border: none;
  }
  .liste-article{
    margin-bottom: 25px;
  }
  .liste-article .list-article-bloc{
    position: relative;
    width: 100% !important;
    text-align: center;
    height: 100%;
  }
  .liste-article .ref{
    position: relative;
    text-align: right;
    margin-top: 15px;
  }
  .liste-article .ref p{
    position: relative;
    top: 50%;
    z-index: 2;
    color: #000 !important;
    font-size: 14px;
    height: 30px;
    padding-top: 5px;
  }
  .liste-article p{
    color: #413e3e;
  }
  .liste-article .gray-light{
    color: #9F9F9F;
    font-size: 0.8em;
    height: -webkit-max-content;
    height: max-content;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    margin: 0;
  }
  .liste-article .image-article{
    max-height: 180px;
    transition: 0.5s;
    text-align: center;
  }
  .liste-article span{
    color: #9F9F9F !important;
  }
  .rectangle-article{
    position: absolute;
    z-index: 1;
    right: 0;
    height: 30px;
  }
  .headerArticle{
    position: relative;
    height: 240px;
    min-height: 240px;
    max-height: 240px;
  }
  .headerArticle img{
    position: absolute;
    top: 0;
    left: 0;
  }
  .headerArticle div{
    position:relative;
    padding: 25px;
    z-index: 2;
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .back-btn{
    position: relative;
    color: #7A7A7A;
    padding: 25px;
  }
  .back-btn button{
    text-decoration: none;
    color: #000;
  }
  .back-btn button:hover{
    color: #000;
    text-decoration: none;
  }
  .description-distance{
    margin-top: 50px !important;
    margin-bottom: 25px;
  }
  
  .article-taille ul{
    list-style: none;
    padding-left:0px !important;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .article-taille ul li{
    border: solid #A4A4A4 1px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 20px;
    margin-top: 20px;
    width: -webkit-max-content;
    width: max-content;
    min-width: 50px;
    height: 50px;
    color: #A4A4A4;
  }
  .similaires-article-bloc{
    position: relative;
    border: solid #9D9D9D 1px;
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 25px;
    margin-top: 25px;
    height: 100%;
    min-height: 350px;
    max-height: 350px;
  }
  .similaires-article-bloc img{
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 80%;
  }
  .similaires-article-bloc div{
    padding-left: 20px;
    padding-right: 20px;
  }
  .jaune{
    color: #FFD102;
    margin-bottom: 20px;
  }
  .gris{
    color:#7A7A7A;
    margin-bottom: 20px;
  }
  .mention-legale {
    margin-bottom: 150px;
  }
  .mention-legale p {
    color:#7A7A7A;
    text-align: justify;
  }
  .mention-informations h5{
    font-weight: bold;
  }
  
  .contact{
    border: 1px solid #DCDCDC;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 20px;
  padding-top: 40px;
  height: 100%;
  }
  .contact-formulaire{
   background-color: #F7F9FB;
  }
  .contact h3{
    color:#7A7A7A;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .contact span{
    color:#7A7A7A;
  }
  .contact-info div{
    padding: 2px;
  }
  
  .contact-icon{
    width: 20%;
  }
  .contact-right{
    width: 80%;
  }
  .contact-icon img{
    width: 80% !important;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .contact-icon .icon-footer{
    width: 50% !important;
  }
  .contact-formulaire input, textarea{
    width: 100%;
    border: 1px solid #A8A8A8;
    box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  padding-left: 15px;
  margin-bottom: 20px;
  }
  .contact-formulaire textarea{
    height: 150px;
  }
  .contact-formulaire button{
    background: #FFD102;
    border-radius: 20px;
    position: relative;
    width: 200px;
    left: 100%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .contact-formulaire button span{
    color: #fff !important;
  }
  .contact-formulaire button:hover{
    background: #18C0F5;
  }
  .contact-map{
    padding: 0 !important;
    height: 100%;
  }
  
  .contact-map iframe{
    height: 100%;
    border-radius: 20px;
    min-height: 400px;
  }
  
  .carousel .thumb{
    height: 80px !important;
  }
  .carousel .slide{
  background-color: rgba(0,0,0,0) !important;
  }
  
  .carousel-status{
    display: none !important;
  }
  .carousel.carousel-slider .control-arrow{
    background-color: none !important;
  }
  .carousel.carousel-slider .control-arrow:hover {
    background:none !important;
    background-color: none !important;
  }
  
  .carousel .control-next.control-arrow:before {
    border-left: 12px solid #000 !important;
  }
  
  .carousel .control-prev.control-arrow:before {
    border-right: 12px solid #000 !important;
  }
  
  .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
  }
  
  .carousel .thumb.selected, .carousel .thumb:hover {
    border: 1px solid lightgray !important;
  }
  
  .filter-hover{
    position: absolute;
    background-color: rgba(255,98,17,1);
    top: 0;
    right: 0;
    height: 0%;
    width: 0%;
    z-index: 5;
    transition: 0.5s;
  }
  .list-article-bloc:hover .filter-hover{
    width: 50px;
    height: 50px;
  }
  .similaires-article-bloc .filter-hover{
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 0 !important;
  }
  .similaires-article-bloc:hover .filter-hover{
    width: 25% !important;
    height: 15% !important;
  }
  .similaires-article-bloc img{
    transition: 0.5s;
  }
  .similaires-article-bloc{
    overflow: hidden;
  }
  .similaires-article-bloc:hover img{
    -webkit-transform: scale(1.1) translateX(-50%);
            transform: scale(1.1) translateX(-50%);
  }
  .list-article-bloc:hover .image-article{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .filter-hover svg{
    width: 20%;
    height: 20%;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
  }
  .carousel .control-dots .dot{
    display: none !important;
  }
  
  .container-navigation{
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .accordion{
    margin-bottom: 50px !important;
  }
  .filtet-title .desktop{
    width: 100% !important;
  }
  .card-header{
    background-color: #fff !important;
  }
  .card{
    border: none !important
  }
  .facebook:hover a svg{
    color: #3b5998 !important;
  }
  .linkedin:hover img{
    color: #0e76a8 !important;
  }
  .pageErreur h1{
    color: #FFD102;
    font-size: 6em;
    margin-bottom: 25px;
  }
  .pageErreur h4{
    color: #7A7A7A;
    margin-bottom: 25px;
  }
  .pageErreur span{
    color: #7A7A7A;
  }
  .pageErreur p{
    color: #7a7a7a;
    font-weight: bold;
    font-size: 1.1em;
    margin-top: 25px;
  }
  .pageErreur ul {
    list-style: none;
    padding: 0 !important;
  }
  .pageErreur ul a{
    text-decoration: none !important;
    color: #FFD102 !important;
  }
  
  .liens-utils a {
    text-decoration: none !important;
  }
  .liens-utils a:hover {
    color: #18C0F5 !important;
  }
  .col-contact{
    margin-bottom: 25px;
    text-align: left !important;
  }
  @media (max-width: 576px) {
    .row-contact .col-contact{
      margin-top: 50px;
      text-align: left !important;
    }
    .carousel-Accueil{
      display: none !important;
    }
    .footer-contact-info{
      display: none !important;
    }
    .logo img {
      width: auto !important;
      position: relative !important;
      top: 100% !important;
      -webkit-transform: translateY(-100%) !important;
              transform: translateY(-100%) !important;
      margin-bottom: -63px !important;
      margin-top: 0px !important;
    }
    .footer-item{
      margin-bottom: 15px;
    }
    .footer{
      padding-top: 25px;
    }
    .presentation{
      -webkit-flex-direction: column-reverse;
              flex-direction: column-reverse;
    }
    .container-navigation{
      -webkit-justify-content: flex-start !important;
              justify-content: flex-start !important;
    }
    .headerArticle{
      display: none !important;
    }
    .Breadcrumb{
      -webkit-flex-direction: column;
              flex-direction: column;
    }
    .searchBar{
      width: 100% !important;
    }
    .filtet-title div div{
      width: 100% !important;
    }
    .navigation{
      height: 100% !important;
    }
    .desktop{
      display: none;
    }
  
    .filter{
      padding: 0 !important;
    }
  
    .filter li{
      width: 100% !important;
    }
    .no-padding{
      padding: 0 !important;
    }
    .mobile{
      margin-bottom: 50px !important;
    }
    .liens-utils a {
      font-size: 20px !important;
      margin: 5px;
    }
    .info-top-section{
      -webkit-flex-direction: column;
              flex-direction: column;
    }
    .liens-utils{
      -webkit-flex-direction: column;
              flex-direction: column;
    }
    .liens-utils .liens-utils-seconde-col{
      margin-left: 0px !important;
    }
    .inscription-newsleter-pop-up{
      height: 400px !important;
      width: 100% !important;
    }
   }
  
  @media (max-width: 768px) {
    .navigation{
      height: 100% !important;
    }
    .carousel-Accueil{
      display: none !important;
    }
    .container-navigation{
      -webkit-justify-content: flex-start !important;
              justify-content: flex-start !important;
    }
    .presentation{
      -webkit-flex-direction: column-reverse;
              flex-direction: column-reverse;
    }
    .footer-contact-info{
      display: none !important;
    }
    .footer-item{
      margin-bottom: 25px;
    }
    .footer{
      padding-top: 25px;
    }
    .inscription-newsleter-pop-up{
      height: 500px;
      width: 90%;
    }
    .facebook img{
      width: 100% !important;
    }
    .linkedin img{
      width: 100% !important;
    }
    .f-end {
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
    }
   }
  
  @media (max-width: 992px) {
    .navigation{
      height: 100% !important;
    }
    .container-navigation{
      -webkit-justify-content: flex-start !important;
              justify-content: flex-start !important;
    }
    .bloc-chiffre{
      width: 100%;
    }
    .footer-contact-info{
      background-color: #fa5d0c ;
      padding-right: 15px;
      padding-left: 15px;
      border-radius: 15px;
    }
    .image-bloc-orange{
      display: none !important;
    }
    .footer-contact-info p{
      width: auto !important;
    }
    .center-vertical{
      text-align: center !important;
    }
    .full-height{
      height: 100% !important;
    }
    .presentation{
      -webkit-flex-direction: column-reverse;
              flex-direction: column-reverse;
    }
    .pageErreur{
      margin-bottom: 80px;
    }
   }
  
  @media (min-width: 1200px) {
    .mobile{
      display: none;
    }
  
   }
  
   @-webkit-keyframes example {
    from {
      height: 70%;
      left: 0;
      top: 120%;
    }
    
    to {
      height: 40%;
      left: 50%;
      top: 50%;
    }
  }
  
   @keyframes example {
    from {
      height: 70%;
      left: 0;
      top: 120%;
    }
    
    to {
      height: 40%;
      left: 50%;
      top: 50%;
    }
  }
  .input-custume{
    position: relative;
    height: 50px !important;
    padding: 0 !important;
    background-color: #fff !important;
    width: -webkit-max-content;
    width: max-content;
  }
  .input-custume input{
    position: relative;
    height: 100%;
    border: none !important;
    background-color: rgba(0,0,0,0);
    padding: 5px !important;
    padding-left: 10px !important;
  }
  .input-custume button{
    position: absolute;
    top: 0;
    height: 100% !important;
    text-decoration: none !important;
    border: none !important;
    background-color: #18C0F5;
    padding: 5px !important;
    min-width: 80px !important;
    overflow: hidden;
  }
  .input-custume img{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    height: 40%;
    color: #fff;
  }
  
  .input-custume button:hover img{
    -webkit-animation-name: example;
            animation-name: example;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
  }
  .confiance img{
    margin-top: 50px;
  }
  .skeleton span {
    position: relative;
    left: 100%;
    -webkit-transform: translateX(-100%) scale(1, 0.60);
            transform: translateX(-100%) scale(1, 0.60);
  }
  .inscription-newsleter-pop-up{
    display: none; 
    color: #2a2c30;
    text-align: center;
    z-index: 3000;
    position: fixed;
    height: 500px;
    width: 60%;
    background-size: 100% 100%;
    left: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    padding: 20px;
    border-radius: 10px;
    -o-box-shadow: 1px 2px 5px 0px #000000;
    box-shadow: 1px 2px 5px 0px #000000;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#000000, Direction=171, Strength=5);
    -webkit-animation: notifPopUp 3s; /* Safari 4+ */ /* Fx 5+ */ /* Opera 12+ */
  animation:         notifPopUp 3s; /* IE 10+, Fx 29+ */
  }
  .inscription-pop-up{
    position: absolute;
    width: 38% !important;
    right: 5% !important;
    top: 90%;
    -webkit-transform: translateY(-100%) !important;
            transform: translateY(-100%) !important;
  
  }
  .inscription{
    padding: 20px;
    border-radius: 10px;
    background-color: #000000;
    text-align: center;
    width: 105%;
    -webkit-transform: translateX(-2.5%);
            transform: translateX(-2.5%);
  
  }
  .inscription input{
    width: 95%;
    height: 35px;
    margin-bottom: 5px;
    display: block;
    border-radius: 10px;
    border: solid 0px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  font-family: 'Fira Sans', sans-serif;
  }
  .fermer-newsletter{
    position: absolute !important;
    top: 10px;
    right: 15px;
    cursor: pointer !important;
  }
  .inscription button{
    color: #2a2c30;
    background-color: #ffea2a;
    width: 95%;
    height: 35px;
    display: block;
    border-radius: 10px;
    border: solid 0px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    -o-box-shadow: 1px 3px 5px 0px #222427;
    box-shadow: 1px 3px 5px 0px #222427;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#222427, Direction=171, Strength=5);
    cursor: pointer;
  }
.devis{
    background-color: #18C0F5;
    padding-top: 25px;
    padding-bottom: 25px;
}

.devis .label{
    color: #fff;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
}
.devis input{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.58);
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    font-family: 'Fira Sans', sans-serif;    font-style: normal;
    font-weight: normal;
    margin: 5px;
    margin-bottom: 15px;
}
.devis input::-webkit-input-placeholder{
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    color: #C4C4C4;
}
.devis input:-ms-input-placeholder{
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    color: #C4C4C4;
}
.devis input::placeholder{
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    color: #C4C4C4;
}
.devis input:focus{
    outline: none;
    border-radius: 10px;
    border: solid #000 1px;
}

.devis textarea{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.58);
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    font-family: 'Fira Sans', sans-serif;    font-style: normal;
    font-weight: normal;
    margin: 5px;
    margin-bottom: 15px;
    height: 250px;
    min-height: 250px;
}

.devis textarea:focus{
    outline: none;
    border-radius: 10px;
    border: solid #000 1px;
}
.devis button{
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: -webkit-max-content;
    width: max-content;
    border-radius: 25px;
    border: none;
    padding: 5px;
    padding-right: 15px;
    padding-left: 15px;
    background-color: #18C0F5;
    color: #fff;
    font-weight: bold;
    font-size: 22px;
}
.devis button:hover{
    background-color: #fff;
    color: #18C0F5;
}
.devis button:active{
    background-color: #28a745;
    color:#fff;
}
.devis .locationMois{
    position: absolute;
    top: calc(50% - 3px);
    right: 8%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: lightgray;
}

.produit-bloc{
    position: relative;
    width: 80%;
    height: 100% !important;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-bottom: 20px;
}
.image-produit-bloc{
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.52);
    box-sizing: border-box;
    border-radius: 10px;
    height: 250px;
    max-height: 250px;
    overflow: hidden;
    margin-bottom: 15px;
}
.image-produit-bloc img{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
    max-width: 100%;
}
.info-produit-bloc{
    text-align: right;
    padding: 5px;
}
.info-produit-bloc h3{
    font-family: 'Montserrat', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 28px;
line-height: 33px;
text-align: right;

color: #18C0F5;

}
.info-produit-bloc span{
    font-family: 'Fira Sans', sans-serif;
font-style: normal;
font-weight: 200;
font-size: 18px;
color: rgba(0, 0, 0, 0.47);
}
.image-produit-bloc:hover .hover-produit-show{
    height: 100% !important;
}
.hover-produit-show{
    -webkit-transform:translateY(-100%);
            transform:translateY(-100%);
    position: absolute;
    height: 0%;
    width: 100%;
    top: 100%;
    background-color: rgba(24, 295, 245, 0.4);
    transition: 1s;
    cursor: pointer;
    overflow: hidden;
}
.icon-show-produit{
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    color: #fff;
}
.icon-show-produit svg{
    font-size: 2rem;
}
.marque-produit{
    text-transform: capitalize;
}
.rupture{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}
